import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import * as _ from 'lodash-es';

/**
 * similar api to jQuery's .animate()
 * @param {() => void} cb
 */
const animate = (el, properties, duration, cb) => {
  el._timeout = setTimeout(() => {
    const ts = [];
    if (duration) for (const key in properties) ts.push(`${key} ease-out ${duration}ms`);
    el.style.transition = ts.join(', ') || 'none';
    for (const key in properties) el.style[key] = properties[key];
    el._timeout = setTimeout(cb, duration);
  }, 150);
};

const COLORS = ['#ef476f', '#f78c6b', '#ffd166', '#06d6a0', '#118ab2'];

const initialStyles = () => ({
  top: '-30px',
  left: `${5 + Math.random() * 90}%`,
});

const create = () => {
  const width = Math.random() * 30;
  const height = width * 0.4;
  const el = document.createElement('div');
  el.className = 'Confetti--bit';
  const style = {
    width: `${width}px`,
    height: `${height}px`,
    backgroundColor: _.sample(COLORS),
    opacity: Math.random() + 0.5,
    transform: `rotate(${Math.random() * 360}deg)`,
    ...initialStyles(),
  };
  for (const k in style) el.style[k] = style[k];
  return el;
};

const drop = (el, iterations) => {
  el._timeout = setTimeout(() => {
    // eslint-disable-next-line radix
    const left = Number.parseInt(el.style.left.substring(0, el.style.left.length - 1));
    animate(
      el,
      {
        top: '100%',
        left: `${left + (Math.random() - 0.5) * 15}%`,
      },
      Math.random() * 2000 + 2000,
      () => {
        const nextIter = iterations == null ? null : iterations - 1;
        if (nextIter === 0) {
          el.remove();
          if (el._timeout) clearTimeout(el._timeout);
        } else {
          animate(el, initialStyles(), 0, () => {
            drop(el, nextIter);
          });
        }
      },
    );
  }, Math.random() * 4000);
};

const Confetti = ({ amount = 200, iterations = 1, className = '', fullPage = false }) => {
  const containerRef = useRef();

  useEffect(() => {
    const con = containerRef.current;
    for (let i = 0; i < amount; i++) {
      const el = create(i);
      con.appendChild(el);
      drop(el, iterations);
    }
    return () => {
      while (con.firstChild) {
        const el = con.lastChild;
        if (el._timeout) clearTimeout(el._timeout);
        con.removeChild(el);
      }
    };
  }, [amount, iterations]);

  return (
    <>
      <div
        ref={containerRef}
        className={clsx('Confetti', className, fullPage && 'Confetti--fullpage')}
      />

      <style jsx global>{`
        .Confetti {
          pointer-events: none;

          &--fullpage {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 100;
          }

          &--bit {
            position: absolute;
            z-index: 100;
          }
        }
      `}</style>
    </>
  );
};

export default Confetti;
