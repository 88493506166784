import clsx from 'clsx';
import React from 'react';

const CTAButton = ({
  onClick,
  children,
  className,
}: {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={clsx('tw-inline-block', 'tw-p-1', className)}>
      <button
        onClick={onClick}
        type="button"
        className={clsx(
          'tw-transition',
          'tw-duration-300',
          'tw-ease-in-out',
          'tw-transform',
          'hover:tw-text-white',
          'hover:tw-scale-105',
          'active:tw-scale-100',
          'font-sans-serif',
          'tw-px-4',
          'tw-py-2',
          'tw-bg-terra',
          'tw-text-white',
          'tw-rounded-lg',
          'tw-whitespace-nowrap',
        )}
      >
        {children}
      </button>
    </div>
  );
};

export default CTAButton;
