export const GUEST_BENEFITS = [
  {
    title: 'Curated Retreats',
    description:
      'Discover our handpicked selection of unique, verified accommodations nestled in nature',
    wandergladeHas: true,
    competitorHas: false,
  },
  {
    title: 'Seamless Trip Planning',
    description:
      'Effortlessly plan your entire escape, from booking your dream rental to mapping out must-see attractions with our AI-assisted travel planner',
    wandergladeHas: true,
    competitorHas: false,
  },
  {
    title: 'Transparent Pricing',
    description:
      'No surprises at checkout - the price you see is the price you pay, with no hidden fees',
    wandergladeHas: true,
    competitorHas: false,
  },
  {
    title: 'Community-Curated Maps',
    description:
      'Leverage the wisdom of fellow travelers with user-generated maps for insider tips and hidden gems',
    wandergladeHas: true,
    competitorHas: false,
  },
  {
    title: '24/7 Customer Support',
    description:
      'Get help anytime, anywhere with our dedicated support team, available around the clock',
    wandergladeHas: true,
    competitorHas: false,
  },
  //   {
  //     title: 'Flexible Cancellation Policies',
  //     description:
  //       'Change of plans? No problem. Enjoy peace of mind with flexible cancellation policies',
  //     wandergladeHas: true,
  //     competitorHas: false,
  //   },
  {
    title: 'Secure Payment Processing',
    description: 'Book with confidence knowing your payment information is safe and secure',
    wandergladeHas: true,
    competitorHas: true,
  },
];

export const FAQs = [
  {
    question: 'What is Wanderglade?',
    answer:
      'Wanderglade is the ultimate online destination for those eager to explore beyond the usual, offering truly unique glamping experiences. We focus on tranquility and a personalized touch, curating a selection of rural retreats—from modern yurts to cozy cabins and everything in between. For travelers, Wanderglade provides a doorway to peaceful escapes with transparent, all-in pricing and the option to craft personalized travel maps for a truly tailored getaway. For hosts, our platform features low fees, a pool of verified guests, and dedicated support, making it ideal for showcasing your special property to those seeking a memorable escape from the everyday.',
  },
  {
    question: 'When will Wanderglade launch?',
    answer:
      'Wanderglade is set to launch this Summer. Sign up for our newsletter to be the first to know when we go live!',
  },
  {
    question: 'What is the Wanderglade travel planner?',
    answer:
      'Our AI-assisted travel planner is designed to help you effortlessly plan your entire escape. No more juggling multiple tabs - with Wanderglade, you can book your dream rental and map out must-see attractions all in one place. Plus, you can share your itinerary with friends and family for easy collaboration.',
  },
];
